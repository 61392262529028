import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function featureEnabled(featureName, user) {
  return user?.features?.includes(featureName);
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return "";

  // Remove all non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Check if the cleaned number has the correct length
  if (cleaned.length !== 11) return phoneNumber;

  // Format the number
  const country = cleaned[0];
  const area = cleaned.slice(1, 4);
  const local = cleaned.slice(4, 7);
  const number = cleaned.slice(7, 11);

  return `+${country} ${area}-${local}-${number}`;
}
