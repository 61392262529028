import axios from "axios";
import { v4 as uuidv4 } from "uuid";

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REACT_APP_API, // Replace with your API base URL
  // other default settings
});

const getAuthHeaders = () => {
  const client = localStorage.getItem("client");
  const uid = localStorage.getItem("uid");
  const accessToken = localStorage.getItem("access-token");
  const device_id = localStorage.getItem("device_id") || uuidv4();

  return { client, uid, accessToken, device_id };
};

const persistUser = (authHeaders) => {
  if (authHeaders["client"] && authHeaders["client"] !== "") {
    window.localStorage.setItem("client", authHeaders.client);
  }
  if (authHeaders["access-token"] && authHeaders["access-token"] !== "") {
    window.localStorage.setItem("access-token", authHeaders["access-token"]);
  }
  if (authHeaders["uid"] && authHeaders["uid"] !== "") {
    window.localStorage.setItem("uid", authHeaders.uid);
  }
};

axiosInstance.interceptors.request.use(
  function (config) {
    const { client, uid, accessToken, device_id } = getAuthHeaders();

    config.headers["device-id"] = device_id;

    if (
      !config.url.includes("auth/sign_in") &&
      !config.url.includes("auth/password")
    ) {
      config.headers["access-token"] = accessToken;
      config.headers["uid"] = uid;
      config.headers["client"] = client;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    persistUser(response.headers);
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
